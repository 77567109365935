import LiveLiveImage from "../../images/photofolio_layout/livelivefilms.webp";
import AudioVisual from "../../images/photofolio_layout/Commercial Advertisement.webp";
import EventsProduction from "../../images/photofolio_layout/Events Production.webp";
import Documentaries from "../../images/photofolio_layout/Documentaries.webp";

import CommercialAdvertisement from "../../images/photofolio_layout/Commercial Advertisement.webp"
import Animation from "../../images/photofolio_layout/Animation Production.webp"
import MusicVideos from "../../images/photofolio_layout/Music Videos.webp";
import WeddingCoverage from "../../images/photofolio_layout/Wedding Coverage.webp"


export const homecards = [
  { text1: "Live Live", text2: "films", img: LiveLiveImage,path:'/portfolio' },
  { text1: "Audio-Visual", text2: "Production", img: AudioVisual,path:'/pages/audio_visual' },
  { text1: "Events", text2: "Production", img: EventsProduction,path:'/pages/events_production' },
  { text1: "Documentaries", text2: "", img: Documentaries,path:'/pages/documentaries'},
];
export const AudioVisualProduction = [
  {
    text1: "Commercial",
    text2: "Advertisement",
    img: CommercialAdvertisement,
    path: "/pages/commercial_advertisement",
  },
  { text1: "Animation",     text2: "Production",
    img: Animation, path: "/pages/animation" },
  {
    text1: "Music",
    text2: "Video",
    img: MusicVideos,
    path: "/pages/Music_videos",
  },
  {
    text1: "Wedding",
    text2: "Coverage",
    img: WeddingCoverage,
    path: "/pages/Wedding_Coverage",
  },
];
