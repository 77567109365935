import React, {useState} from 'react';

const VideoCenter = ({ videos, saveVideo,deleteVideo, embedVideo, viewInLibrary  }) => {
  const modal = document.getElementById("embedVideoModal");

const closeEmbed =()=>{
  modal.style.display = " none"
}

  return (
    <div className="video-center">
      <div className="video-library-header">
        <section className="dashboard-section__youtube">
        <h2>Video Management Center</h2>

            <input type="text" placeholder="Enter YouTube URL" id="youtubeLinkInput"/>
            <button onClick={saveVideo}>Save</button>
            <button onClick={viewInLibrary}>View in Library</button>
            <button onClick={deleteVideo}>Delete</button>
        </section>
      </div>

      {/* Modal for embedding videos */}
      <div id="embedVideoModal" style={{ display: "none" }} className="modal">

        <div className="modal-content">
            <icon class="close-icon" id="closeEmbed" onClick={closeEmbed} >&times;</icon>

          <h4>Select a Page to Embed Video</h4>
          <select id="pageSelector">
            <option value="LiveLive_Films">Livelive Films</option>
            <option value="Documentaries">Documentaries</option>
            <option value="Music_Videos">Music Videos</option>
            <option value="Animations">Animations</option>
            <option value="Wedding_Coverage">Wedding Coverage</option>
            <option value="Commercial_Advertisements">Commercial Ads</option>
            <option value="Events_Production">Events Production</option>
          </select>
        </div>
        <div className="modal-footer">
          <button id="embedButton" className="modal-close waves-effect waves-green btn-flat">
            Embed
          </button>
        </div>
      </div>

      <div className="video-library-content">
        <div className="video-library">
          {videos.length > 0 ? (
            videos.map((video, index) => (
              <div key={index} className="video-card">
                <a href={video.video_url} target="_blank" rel="noopener noreferrer">
                  <img src={video.thumbnail_url || 'placeholder-image-url'} alt={video.title || 'No title available'} className="video-thumbnail" />
                </a>
                <h3>{video.title || 'No title available'}</h3>
                <button onClick={() => embedVideo(video)}>Launch Embed Dialog</button>
              </div>
            ))
          ) : (
            <p>No videos available</p>
          )}
        </div>
      </div>

    </div>
  );
};

export default VideoCenter;
