
import React, { useState, useEffect } from 'react';
import hostserver from '../host_config'; // import hostserver


const PortfolioPage = ({ PageTitle  }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPortfolioVideos = async () => {

      try {
        const response = await fetch(`${hostserver}/api/admin/portfolio/fetch`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            PageTitle,
          }),
        });
  
        // Check if the response is OK (status code in the range 200-299)
        if (!response.ok) {
          
          throw new Error('Failed to fetch videos');
        }
  
        const data = await response.json();
        setVideos(data.videoIndexes);

        
        
        // console.log("tryblock exec")
      } catch (err) {
        console.log('try block failed', err);
        setError('Failed to fetch videos');
      } finally {
        setLoading(false);
      }
      
    };

    fetchPortfolioVideos();
  }, [PageTitle ]);
  
  return (
    <div>
      {loading && <p className='isloading' >Loading videos...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && videos.length > 0 && (
        <div className="video-grid">
          {videos.map((videoIndexes, index) => (
            <VideoCard key={index} videoIndex={videoIndexes} />
          ))}
        </div>
      )}
    </div>
  );
};

 
const VideoCard = ({ videoIndex }) => {
  const [videoMeta, setVideoMeta] = useState(null);

  useEffect(() => {
    const fetchVideoMeta = async () => {
      try {
        const response = await fetch(`${hostserver}/api/admin/portfolio/display`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ videoIndexes: [videoIndex] }), // Single index array
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        // console.log(data);
        
        setVideoMeta(data[0]); // Assuming single video returned
      } catch (err) {
        console.error('Failed to fetch video metadata', err);
      }
    };

    fetchVideoMeta();
  }, [videoIndex]);

  if (!videoMeta) return <p className='isloading'>Loading...</p>;

  return (


  <div class="position-card">
     <a  href={videoMeta.video_url} target="_blank" rel="noopener noreferrer">
      <img src={videoMeta.thumbnail_url} alt={videoMeta.title} class="position-card-thumbnail" />
      </a>
    <h3>{videoMeta.title}</h3>
        {/* <p>Position Card Description</p> */}
  </div>

 


  );
};


export default PortfolioPage;
