import React from 'react';

const EmailListing = ({ emails, fetchEmails, generateBulkEmailLink }) => {
  return (
    <section className="dashboard-section__email">
      <h2>Email Management</h2>
      <button onClick={fetchEmails}>Email List</button>
      <button onClick={generateBulkEmailLink}>Send Bulk Email</button>
      <table>
        <thead>
          <tr>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {emails.map((email, index) => (
            <tr key={index}>
              <td>{email.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default EmailListing;
