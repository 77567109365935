import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Search from "../../components/Search/Search";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";
import PositionCards from "../../components/PortofolioPageCards/PositionCards";
import Footer from "../../components/Footer/Footer";

import LiveLiveLandingImage from "../../images/photofolio_layout/Music Videos.webp";

const Music_videos = () => {
  const PageTitle="Music_Videos"
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <PageTitle pageTitle="Portfolio" path="/pages/audio_visual" />
      <Search />
      <BannerCards
        topicOne="Music"
        topicTwo="Videos"
        portofolioPageImage={LiveLiveLandingImage}
      />
      <PositionCards  PageTitle={PageTitle} />
      <Footer />
    </div>
  );
};

export default Music_videos;
