import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './signup_section.css';
import hostserver from '../host_config'; // import hostserver


const Signup = () => {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [location, setLocation] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [locationError, setLocationError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = async () => {
    // Reset previous error messages
    setDisplayNameError('');
    setEmailError('');
    setPasswordError('');
    setLocationError('');

    // Validate inputs
    if (!displayName.trim()) {
      setDisplayNameError('Display Name is required');
      return;
    }
    if (!email.trim()) {
      setEmailError('Email is required ! !');
      return;
    }
    if (!password.trim()) {
      setPasswordError('Password is required');
      return;
    }
    if (!location.trim()) {
      setLocationError('Location is required');
      return;
    }

    try {
      // Make HTTP POST request to the signup API endpoint
      const response = await fetch(`${hostserver}/api/admin/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ displayName, email, password, location }),
      });

      // Parse response
      const data = await response.json();

      // Handle successful signup
      if (response.ok) {
        // Redirect the user to the login page or dashboard upon successful signup
        navigate('/administration/login');
      } else {
        // Handle signup failure
        setEmailError(data.message);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error occurred during signup:', error);
      setEmailError('An error occurred during signup. Please try again.');
    }
  };

  return (
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Sign Up</div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={displayName}
          placeholder="John Doe"
          onChange={(ev) => setDisplayName(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{displayNameError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={email}
          placeholder="johndoe@email.com"
          onChange={(ev) => setEmail(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder="Enter your password"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{passwordError}</label>
        <span onClick={togglePasswordVisibility} className="togglePassword">
          {showPassword ? 'Hide' : 'Show'}
        </span>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          value={location}
          placeholder="Your Location"
          onChange={(ev) => setLocation(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{locationError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={handleSignup} value={'Sign Up'} />
      </div>
    </div>
  );
};

export default Signup;
