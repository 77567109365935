import React from "react";
import "./Book.css";

import { Link } from "react-router-dom"; 

const Book = () => {
  return (
    <div className="book">
      <div className="container">
        
        <div className="sub-heading">
          <p>What We Do</p>
        </div>
          <div className="rows">
          <p className="book-title">1. Audio-Visual Productions</p>
          <div className="book-description">
            Elevate your message with captivating audio visual production
            services tailored to your needs. Blending creativity with technical
            expertise to deliver stunning visuals and immersive sound
            experiences that leave a lasting impact.
          </div>

          <ul className="section-ul">
            <li className="section-li">
              <p>- Commercial Advertisement</p>
            </li>
            <li className="section-li">
              <p>- Animation Production</p>
            </li>
            <li className="section-li">
              <p>- Music Video</p>
            </li>
            <li className="section-li">
              <p>- Wedding Coverage</p>
            </li>
            
          </ul>
        </div>
        <div className="rows">
          <p className="book-title">2. Events Production</p>
          <div className="book-description">
            We turn your vision into an unforgettable event experience. Bringing
            your ideas to life with creativity, precision, and seamless
            execution. Whether it's a corporate gathering, product launch,
            wedding, or any special occasion, we handle every aspect from
            concept development to logistics management, ensuring a flawless
            event that exceeds expectations. Let us elevate your next event to
            new heights. Contact us today to begin planning your extraordinary
            experience!
          </div>
        </div>
        <div className="rows">
          <p className="book-title">3. Documentaries</p>
          <div className="book-description">
            We craft compelling narratives and fostering meaningful connections
            between your brand and your audience. Our tailored strategies ensure
            that your brand's identity resonates effectively across various
            platforms, leaving a lasting impression. Trust us to elevate your
            brand presence and drive engagement like never before. Discover the
            difference our brand representation services can make for your
            business today.
          </div>
          <div className="rows">
          <p className="book-title">4. Live Live Films</p>
          <div className="book-description">
            Live Live films seeks to discover a Kenyan cinematic language
            through constant Experimention in films that go far beyond
            storytelling in creating visceral audio-visual experiences.
          </div>
        </div>
        </div>
        <Link to="https://wa.me/254794878861" className="book-btn" target="_blank">Book with us Today</Link>

        
      </div>
    </div>
  );
};

export default Book;
