import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./ViewCsv.css";

const ViewCSV = () => {
  const [newsletterData, setNewsletterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get('https://rapidapi.liveliveentertain.com/api/admin/newsletter/get');

        const data = response.data;

        setNewsletterData(data.emails); // Update newsletterData with fetched emails
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching newsletter data:", error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="viewFile">
      <h2>Newsletter Subscribers</h2>
      <table className="newsletterTable">
        <thead>
          <tr>
            <th>Email</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {newsletterData.map((entry, index) => (
            <tr key={index}>
              <td>{entry.email}</td>
              <td>{new Date(entry.timestamp.$date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCSV;
