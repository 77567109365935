import "./sectionA.css";
import { Link } from "react-router-dom"; 

const SectionA = () => {
  return (
    <div className="section-a" id="section-a-company-objectives">
      <div className="container">
        <div className="row flex">
          <div className="cards">
            <div className="sub-title">Vision</div>
            <p className="description" style={{ textAlign: "start" }}>
            To be the preferred creative media solutions provider in Africa
            </p>
          </div>
          <div className="cards">
            <div className="sub-title">Mission</div>
            <p className="description" style={{ textAlign: "start" }}>
              We are dedicated to crafting unparalleled media experiences that
              resonate globally, while incessantly embracing new technological
              frontiers to ensure our offerings stay at the pinnacle of
              innovation.
            </p>
          </div>
        </div>
      </div>
      <Link to="/portfolio" className="book-btn portfolio-btn">
          View our Portfolio
        </Link>
    </div>
  );
};

export default SectionA;
