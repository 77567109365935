import { Link } from "react-router-dom";
// import cardImage from "../../images/photofolio_layout/Assets_Podcast.png";
import { homecards } from "../Datasheets/Datasheet";
import "./sectionB.css";
const SectionB = () => {
  return (
    <div className="container section-b">
      <div className="sub-heading">
        <p>What We do</p>
        </div>
        <div className="grid">
          {homecards.map((homecard) => (
            <div className="card">
              <Link to={homecard.path}>
                <div className="img-wrapper">
                  <img src={homecard.img} alt="" />
                </div>
                <div className="card-text">
                  <p>{homecard.text1}</p>
                  {homecard.text2 && <p>{homecard.text2}</p>}
                </div>
              </Link>
            </div>
          ))}
        </div>
        
    </div>
  );
};
export default SectionB;
