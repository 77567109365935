



import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "./dashboard_section.css"; // styles sheet for this section 
import hostserver from '../host_config'; // import hostserver



import EmailListing from './EmailListing';
import VideoCenter from './VideoCenter';
import PortfolioManagement from './PortfolioManager';

const Dashboard = () => {

  // admin dashboard autherntication state variables 
  const [isAuthenticated, setIsAuthenticated] = useState(false); // stores the authenticaton state
  const [activeComponent, setActiveComponent] = useState('Dashboard');
  const navigate = useNavigate(); // navigate to admin dash after succesfull login

  //  email listing state variables 
  const [emails, setEmails] = useState([]);
  const [mailtoLink, setMailtoLink] = useState("");
  
  // video center state variables
  const [videos, setVideos] = useState([]);
  
  // portfolio section  state variables 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCategoriesUpdated, setIsCategoriesUpdated] = useState(false);

  


  // --------------------- component state variable holder and updater ------------------------

  useEffect(() => { 
    fetchVideoLibrary();
    fetchPortfolioCategories();
      // updateLibrary();
    }, []);

// -------------------------- authentuntication status handler -----------------------------------

  useEffect(() => {
    // use token for session authentication
    const checkAuth = () => {
      const token = sessionStorage.getItem("token");
      // check if token exist in session storage
      if (token) {
        try {
          // Decode the token to check its validity and expiry
          const decodedToken = jwtDecode(token);

          //check for token validity
          // Optional: You can add additional checks for token expiration here
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            // Token is expired
            sessionStorage.removeItem("token");
            return false;
          }

          // Token is valid
          return true;
        } catch (error) {
          console.error("Token decoding failed:", error);
          sessionStorage.removeItem("token");
          return false;
        }
      } else {
        return false;
      }
    };

    const isAuthenticated = checkAuth();
    
    if (!isAuthenticated) {
      navigate("/administration/login");
    } else {
      setIsAuthenticated(isAuthenticated);
    }
  }, [navigate]);

//------------------------------------------------- email management route handlers ----------------------------------

  const fetchEmails = async () => {
    try {
      const response = await fetch(
        `${hostserver}/api/admin/newsletter/get`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.status !== 200) {
        throw new Error("Failed to fetch emails");
      }

      setEmails(data.emails);
    } catch (error) {
      console.error(error);
    }
  };

  const generateBulkEmailLink = async () => {
    try {
      const response = await fetch(
        `${hostserver}/api/admin/newsletter/send-bulk`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Response is not JSON");
      }

      const data = await response.json();
      // console.log(data); // Debugging output

      setMailtoLink(data.link);
// Automatically open the email client after a delay
alert('Opening in External Email Handler');

// Delay before opening the email handler (e.g., 2 seconds = 2000ms)
setTimeout(() => {
  window.location.href = data.link;
}, 1000);  // 1000 milliseconds = 1 seconds delay

    } catch (error) {
      // console.error("Error:", error);
    }
  };



  // ------------------------------------------ video management route handlers ---------------------------------------------------------
  const saveVideo = async () => {
    const link = document.getElementById("youtubeLinkInput").value; // Grab the value from the input field

  // Check if the video already exists in the library
  const videoExists = videos.some(video => video.video_url === link); // videos is the current video list

  if (videoExists) {
    alert("This video has already been saved to the library.");
    return; // Prevent saving the video again
  }


    try {
      const response = await fetch(
        `${hostserver}/api/admin/youtube/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ link }), // Send the link as part of the request body
        }
      );

      const data = await response.json();

      if (response.ok) {
        await fetchVideoLibrary();
        
      // console.log(data.Message);
      alert(data.Message)
      } else {
        alert("Failed to save YouTube link")
        
      }
     

    } catch (error) {
      // console.error("Error:", error);
      alert("Oops! something went wrong")
    }
  };
  const deleteVideo = async () => {
    const link = document.getElementById("youtubeLinkInput").value; // Grab the value from the input field
    
    if (!link) {
      alert("Please provide a valid video link to delete");
      return; // Exit the function if no link is provided
    }
  
    try {
      const response = await fetch(
        `${hostserver}/api/admin/youtube/delete-video`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ link }), // Some servers may not expect a body with DELETE requests
        }
      );
  
      // Explicitly check for response status
      if (response.status !== 200) {
        const errorData = await response.json();
        alert(`Error deleting video(s): ${errorData.Message || errorData.error}`);
        return; // Exit after showing the error
      }
  
      const result = await response.json();
      // console.log(response.status);
  
      // If status is 200, continue with success actions
      alert("Video deleted successfully");
  
      // Re-fetch portfolios and videos after deletion
      // await deletePortCard(link)
      await fetchPortfolioCategories();
      await fetchVideoLibrary();
      
    } catch (error) {
      alert("Oops! Something went wrong");
      console.error("Error:", error);
    }
  };
  
  const viewInLibrary =  () => {
    // console.log("Button clicked");
  
    try {
      const youtubeLinkInput = document.getElementById("youtubeLinkInput").value;
      if (!youtubeLinkInput) {
        document.getElementById("youtubeLinkInput").classList.add("highlight");
        return;
      } else {
        document.getElementById("youtubeLinkInput").classList.remove("highlight");
      }
  
      // Scroll to the video library section if not visible
      const videoLibraryContainer = document.getElementById("video-library");
      if (videoLibraryContainer) {
        videoLibraryContainer.scrollIntoView({ behavior: "smooth" });
      }
  
      // Highlight the selected video based on the input link
      const selectedVideo = videos.find(
        (video) => video.video_url === youtubeLinkInput
      );
  
      if (selectedVideo) {
        const videoCards = document.getElementsByClassName("video-card");
        for (let i = 0; i < videoCards.length; i++) {
          const card = videoCards[i];
          if (card.querySelector("a").href === youtubeLinkInput) {
            card.classList.add("highlight");
            card.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the highlighted video card
          } else {
            card.classList.remove("highlight");
          }
        }
      } else {
        // console.log("No matching video found for the provided link.");
        alert("No matching video found for the provided link")
      }
    } catch (error) {
      // console.error("Error:", error);
      alert("Oops! something went wrong")
    }
  };
  const embedVideo = async (video) => {
    const modal = document.getElementById("embedVideoModal");
    const pageSelector = document.getElementById("pageSelector");
    const embedButton = document.getElementById("embedButton");

    // Open the modal
    modal.style.display = "block";
    modal.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to the highlighted video card


    // Add event listener to the Embed button inside the modal
    embedButton.onclick = async () => {
      const selectedPage = pageSelector.value;

      if (!selectedPage) {
        alert("Please select a page");
        return;
      }

      const videoDetails = {
        videoUrl: video.video_url, // Access the video URL from the resolved video
        targetPage: selectedPage,
        videoId : video.video_id
      };

      // Close the modal
      modal.style.display = "none";

      // Send data to the backend
      // console.log(videoDetails);

      try {
      const response = await fetch(`${hostserver}/api/admin/youtube/embed-video`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(videoDetails),
        })
        const data = await response.json();
        if (response.status === 200) {
          console.log("reftetching cartegories");
          
          await fetchPortfolioCategories()
          alert("Video embedded successfully!");
        } else {
          
            alert(`An error occurred: ${data.error || "Unknown error"}`);
          
        }
      } catch (error) {
        alert(`Error: ${error.message}`);

        
      }
  };
  };

  const fetchVideoLibrary = async () => {
    try {
    const response = await fetch(
      `${hostserver}/api/admin/youtube/links`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (response.status === 200 && Array.isArray(data.links)) {
      setVideos(data.links);
    } else {
      setVideos([]);
    }
  } catch (error) {
    console.error("Error fetching video library:", error);
  }
  };




  //  -----------------------------------------------------portfolio videos route handlers ----------------------------------------------------------
  const fetchPortfolioCategories = async () => {
    /**
     * The function `fetchPortfolioCategories` fetches portfolio categories from /api/admin/portfolio/categories endpoint. 
     * The respons should contain the cartegories titles(portfolio_page title ) and the associated
     *  videos' (thumbnail_url, video_id, video_url and the title of the video).  this cartegories are displayed in the
     *  portfolio manager page of the admin dashboard . updating the category from this administrative page should exhibit a similar 
     * behaviour in the porfolio pages viewed by the site's users.
     */
    try {
      // console.log("Starting to fetch categories...");

      const response = await fetch(
        `${hostserver}/api/admin/portfolio/categories`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("Response received:", response);

      const data = await response.json();
      // console.log("Data received:", data);

      if (response.status === 200 && Array.isArray(data.categories)) {
        // console.log("Categories fetched successfully:", data.categories);
        setCategories(data.categories);
      } else {
        console.error(
          "Unexpected data format or status code:",
          response.status,
          data
        );
        setCategories([]); // update cartegory state
        // setIsCategoriesUpdated(true); // force ui rerender on category update

      }
    } catch (error) {
      console.error("Error occurred while fetching categories:", error);
    }

    // console.log(categories);
  };
// useEffect(() => {
//   if (isCategoriesUpdated) {
//     // Log or take actions after categories are updated and UI is re-rendered
//     console.log("Categories have been updated.");
//     // Reset the state to avoid repetitive rendering
//     setIsCategoriesUpdated(false);
//   }
// }, [isCategoriesUpdated]);
  const  deletePortCard = async (portfolioTitle,videoId) => {
    setLoading(true);
    setError(null); // Clear any previous errors
    // console.log("deleting video with id: ",videoId ,"from portfolio cartegory with pagetitle: ", portfolioTitle);


    try {
      const response = await fetch(`${hostserver}/api/admin/portfolio/categories/delete-video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          portfolio_title: portfolioTitle, // Pass portfolio title
          video_id: videoId, // Pass the video ID
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // console.log("Video deleted successfully", data);
        alert("Video deleted successfully")
      } else {
        console.error("Failed to delete video:", data.error);
        alert("ERR: Failed to delete video")
        setError(data.error);
      }
    } catch (err) {
      // console.error("Error occurred while deleting video:", err);
      setError("An error occurred while deleting the video.");
    } finally {
      setLoading(false); // End loading state
    }
  };



// ---------------render component -------------------------

  return (
    <div className="dashboard">
      {/* Sidebar Navigation */}
      <div className="sidebar">
        <ul>
          <li
            style={{ textDecoration: activeComponent === 'EmailListing' ? 'underline' : 'none', cursor: 'pointer' }}
            onClick={() => setActiveComponent('EmailListing')}
          >
            Email Listing
          </li>
          <li
            style={{ textDecoration: activeComponent === 'VideoCenter' ? 'underline' : 'none', cursor: 'pointer' }}
            onClick={() => setActiveComponent('VideoCenter')}
          >
            Video Center
          </li>
          <li
            style={{ textDecoration: activeComponent === 'PortfolioManagement' ? 'underline' : 'none', cursor: 'pointer' }}
            onClick={() => setActiveComponent('PortfolioManagement')}
          >
            Portfolio Manager
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="dashboard-section">
        {activeComponent === 'EmailListing' && (
          <EmailListing
            emails={emails}
            fetchEmails={fetchEmails}
            generateBulkEmailLink={generateBulkEmailLink}
          />
        )}
        {activeComponent === 'VideoCenter' && (
          <VideoCenter
            videos={videos}
            embedVideo={embedVideo}
            saveVideo={saveVideo}
            deleteVideo={deleteVideo}
            viewInLibrary={viewInLibrary}
          />
        )}
        {activeComponent === 'PortfolioManagement' && (
          <PortfolioManagement categories={categories} deletePortCard={deletePortCard} />
        )}
      </div>
    </div>
  );


};

export default Dashboard;
