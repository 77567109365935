import React from "react";
import "./PortofolioCards.css";
const BannerCards = ({ topicOne, topicTwo,portofolioPageImage }) => {
  return (
    <div className="container">
      <div className="banner-card" id="banner-card">
        <img src={portofolioPageImage} className="banner-image" />

        <div className="banner-overlay">
          <p className="card-description" id="description">
            <p className="banner-description">{topicOne}</p>
            <p className="banner-description">{topicTwo}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BannerCards;
