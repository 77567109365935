import React from "react";

import { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Search from "../../components/Search/Search";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";
import PositionCards from "../../components/PortofolioPageCards/PositionCards";
import Footer from "../../components/Footer/Footer";
import BrandRepresentationImage from "../../images/photofolio_layout/Documentaries.webp";

const BrandProduction = () => {
  const PageTitle = "Documentaries"; // Variable to manage component logic
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PageTitle pageTitle="Documentaries" path="/portfolio" />
      <Search />
      <BannerCards
        topicOne="Documentaries"
        portofolioPageImage={BrandRepresentationImage}
      />
      <PositionCards  PageTitle={PageTitle} />  {/* Pass componentName as a prop */}
      <Footer />
    </div>
  );
};

export default BrandProduction;
