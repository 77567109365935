import "./Register.css";
import { useState } from "react";
import axios from "axios";


const RegisterItem = () => {
  const [Linkk, setTitle] = useState("");
  const [linkType, setLinkType] = useState("Live Live");
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate inputs before submission

    if (Linkk.trim() === "") {
      setError("Please enter a link");
      return;
    }

    console.log("Form submitted:", { Linkk, linkType });

    setError("");

    const sentData = async () => {
      try {
        const url = "https://rapidapi.liveliveentertain.com/admin/portfolio/add";
        const response = await axios.post(
          url,
          {
            link: Linkk,
            title: linkType,
          },
          {
            header: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.status === 200) {
          throw new Error("Failed to sent data to the database");
        }

        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    sentData();
    setError("");
    setTitle("");
  };

  return (
    <div>
      <div className="register">
        <div className="container">
          <form
            onSubmit={handleSubmit}
            className="registerForm"
            id="registerForm"
          >
            <p className="registrationTitle">ADD PORTFOLIO LINKS</p>
            <div className="input-group">
              <input
                type="text"
                name="Linkk"
                id="Linkk"
                className="admin-input"
                placeholder="Link.."
                value={Linkk}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="input-group">
              <select
                name="linkType"
                id="linkType"
                value={linkType}
                onChange={(e) => setLinkType(e.target.value)}
              >
                <option value="Live Live">Live Live</option>
                <option value="Audio Visual Production">
                  Audio Visual Production
                </option>
                <option value="Events Production">Events Production</option>
                <option value="Brand Representation">
                  Brand Representation
                </option>
              </select>
            </div>
            <div className="input-group">
              <input type="submit" value="SEND" />
            </div>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterItem;
