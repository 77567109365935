import React, { useState } from 'react';

const PortfolioManagement = ({ categories, deletePortCard }) => {
  const [activeIndex, setActiveIndex] = useState(null); // State to track the active accordion item

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the accordion
  };

  return (
    <section className="portfolios accordion">
      <h2>Portfolio Manager</h2>
      {categories.length > 0 ? (
        categories.map((category, index) => (
          <div key={index} className="category accordion-item" id="portfolioCategory">
            <h3
              className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleAccordion(index)}
            >
              {category.title}
            <span className="accordion-icon">&#x25BC;</span>  {/* Chevron down icon */}
            </h3>

            <div
              className={`category-videos accordion-content ${
                activeIndex === index ? 'active' : ''
              }`}
              style={{
                display: activeIndex === index ? 'grid' : 'none' // Show/hide based on state
              }}
            >
              {category.videos.length > 0 ? (
                category.videos.map((video, videoIndex) => (
                  <div key={videoIndex} className="video-card">
                    <a href={video.video_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={video.thumbnail_url || 'placeholder-image-url'}
                        alt={video.title || 'No title available'}
                        className="video-thumbnail"
                      />
                    </a>
                    <h4>{video.title || 'No title available'}</h4>
                    <button
                      className="delete"
                      onClick={() => deletePortCard(category.title, video.video_id)}
                    >
                      Delete Video
                    </button>
                  </div>
                ))
              ) : (
                <p>No videos available for this category.</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No categories available.</p>
      )}
    </section>
  );
};

export default PortfolioManagement;
