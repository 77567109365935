import React from "react";
import Tshirt2 from "../../images/tshirt2.webp";
import "./PhotofolioGrid.css";
const PhotofolioGrid = () => {
  return (
    <div className="photofolioGrid">
      <div className="container">
        <div className="grid">
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
          <div className="card-port">
            <div className="img">
              <img src={Tshirt2} alt="" />
            </div>
            <div className="description">
              <div className="p-ars">
                <p className="item-name">Item Name</p>
                <p className="item-name">Price</p>
              </div>
            </div>
            <div className="card-buttons flex">
              <button>Buy Now</button>
              <button>Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotofolioGrid;
