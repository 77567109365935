import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import Footer from "../components/Footer/Footer";
import Dashboard from "../components/Dashboard/dashboard_section";
import Sidebar from "../components/Dashboard/Sidebar";

const admin_dashboard = () => {
    return (
      <div>
        <Navbar />
        <Dashboard />
      </div>
    );
  };
  
  export default admin_dashboard;
  