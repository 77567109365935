import "./AboutHero.css";
import { Link } from "react-router-dom"; 
const AboutHero = () => {
  return (
    <div className="about-hero" id="about-hero">
      <div className="container">
        <div className="sub-heading">
          <p>Who We Are</p>

        </div>
        <p className="w-description">
         We are an Avant garde Media production house in Kenya
         </p>
      </div>
    </div>
  );
};
export default AboutHero;
