import React from "react";
import "./PhotofolioNavbar.css";
const PhotofolioNavbar = () => {
  return (
    <div className="photofolio-navbar">
     
      <div className="container">
     
        <ul className="flex">
          <li>
            <a href="#">All</a>
          </li>
          <li>
            <a href="#">Clothing</a>
          </li>
          <li>
            <a href="#">Cultery</a>
          </li>
          <li>
            <a href="#">Accessories</a>
          </li>
          <li>
            <a href="#">Stationery</a>
          </li>
          <li>
            <a href="#">Others</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default PhotofolioNavbar;
