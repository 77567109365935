import React,{useEffect} from "react";

import Navbar from "../../components/Navbar/Navbar";

import PageTitle from "../../components/PageTitle/PageTitle";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";

import PositionCards from "../../components/PortofolioPageCards/PositionCards";
import Search from "../../components/Search/Search";

import Footer from "../../components/Footer/Footer";

import EventsProductionImage from "../../images/photofolio_layout/Events Production.webp"

const EventProduction = () => {
  const PageTitle="Events_Production"

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    <div>
      <Navbar />
      <PageTitle pageTitle="Portfolio" path="/portfolio" />
      <Search />
      <BannerCards topicOne="Events" topicTwo="Production" portofolioPageImage={EventsProductionImage} />

      <PositionCards PageTitle={PageTitle}/>
      <Footer />
    </div>
  );
};

export default EventProduction;
