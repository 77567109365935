import { Link } from "react-router-dom";

import { AudioVisualProduction } from "../Datasheets/Datasheet";

import "./PortCards.css";
const PortCards = () => {
  return (
    <div className="container section-b">
      <div className="grid">
        {AudioVisualProduction.map((card) => (
          <div className="card">
            <Link to={card.path}>
              <div className="img-wrapper">
                <img src={card.img} alt="" />
              </div>

              <div className="card-text">
                <p>{card.text1}</p>
                {card.text2 && <p>{card.text2}</p>}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default PortCards;
