import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Search from "../../components/Search/Search";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";
import PositionCards from "../../components/PortofolioPageCards/PositionCards";
import Footer from "../../components/Footer/Footer";

import LiveLiveLandingImage from "../../images/photofolio_layout/Commercial Advertisement.webp"

const CommercialAdvertisement = () => {

  const PageTitle="Commercial_Advertisements"
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <PageTitle pageTitle="Portfolio" path="/pages/audio_visual" />
      <Search />
      <BannerCards topicOne="Commercial" topicTwo="Advertisement"  portofolioPageImage={LiveLiveLandingImage} />
      <PositionCards  PageTitle={PageTitle}/>
      <Footer />
    </div>
  );
};

export default CommercialAdvertisement;
