import Navbar from '../components/Navbar/Navbar'
import AboutHero from '../components/AboutHero/AboutHero'
import SectionA from "../components/Home/SectionA"
import Footer from '../components/Footer/Footer'
import Book from '../components/Booking/Book'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import PageTitle from '../components/PageTitle/PageTitle'
import { useEffect } from 'react'
const About = () => {
 
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (

    <div>
        <Navbar/>
        <PageTitle pageTitle="About Us" path="/" />
        <AboutHero/>
        <Book/>
        <SectionA/>
        <NewsLetter/>
        <Footer/>
    </div>
  )
}

export default About