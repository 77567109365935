import "./Hero.css";
import { Link } from "react-router-dom";
import Arrow from "../../images/photofolio_layout/Asset 1 (1).png"
import { Link as LinkScroll  } from "react-scroll";
const Hero = () => {
  return (
    <div className="hero" id="hero">
      <div className="container">
        <div className="content">
          <p className="content-title">
            WE ARE AN AVANT GARDE
            <br />
            MEDIA PRODUCTION HOUSE
          </p>
          <Link to="https://wa.me/254794878861"><button className="btn">Book With Us Today </button></Link>

          <div className="social-media-icons">
            <div className="columns">
              <ul>
                <li>
                  <Link to="https://www.tiktok.com/@livelive.entertain" target="_blank">
                    {" "}
                    <i class="fa-brands fa-tiktok"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/live_liveentertainment" target="_blank">
                    {" "}
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/photo/?fbid=122112697628172328&set=a.122107261694172328" target="_blank" >
                    {" "}
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/@live_liveentertainment" target="_blank">
                    <i class="fa-brands fa-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="got-top" id="got-top">
          <LinkScroll to="hero" smooth={true}>
            <img src={Arrow} alt="" />
          </LinkScroll>
        </div>
      </div>
    </div>
  );
};

export default Hero;
