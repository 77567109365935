import React from "react";
import "./PageTitle.css";

import { Link } from "react-router-dom"; 


const PageTitle = ({ pageTitle,path }) => {
  return (
    <div className="pageTitle">
      <div className="container">
        <p className="page-title">{pageTitle}</p>

        <p className="arrow-navigator">
            <Link to={path}>
              <i class="fa-solid fa-arrow-left"></i>
            </Link>
        </p>

      </div>
    </div>
  );
};

export default PageTitle;
