import { useState } from "react";
import "./Search.css";
import Arrow from "../../images/photofolio_layout/Asset 1 (1).png";

import { Link } from "react-router-dom";

const Search = () => {
  const [togglerRotated, setTogglerRotated] = useState(false);

  const togglerDisplayer = () => {
    setTogglerRotated(!togglerRotated);
  };

  return (
    <div className="search-section">
      <div className="container">
        <div className="search-div" onClick={togglerDisplayer}>
          <input
            type="text"
            name="search"
            placeholder="What services are you looking for "
          />
          <div className={`toggler ${togglerRotated ? "active" : ""}`}>
            <img src={Arrow} alt="" />
          </div>
        </div>
        {/* services-dropdown */}
        <div className={`services-dropdown ${togglerRotated ? "active" : ""}`}>
          <ul className="dropdown">
            
            <li onClick={togglerDisplayer}>
              <Link to="/pages/audio_visual">Audio Visual Production</Link>
            </li>
            <li onClick={togglerDisplayer}>
              <Link to="/pages/events_production">Events production</Link>
            </li>
            <li onClick={togglerDisplayer}>
              <Link to="/pages/Documentaries">Documentaries</Link>
            </li>
            <li onClick={togglerDisplayer}>
              <Link to="/portfolio">Live Live Films</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Search;
