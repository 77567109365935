import React, { useState } from "react";
import "./NewsLetter.css";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmission = (e) => {
    e.preventDefault();
    console.log("Hello world");
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return;
    }
    console.log("Email submitted:", email);
    setError("");
    setEmail("");

    const sentEmail = async () => {
      try {
        const url = "https://liveliveentertain.com/api/admin/newsletter/add";
        
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }), // Convert the email object to a JSON string
        });
      
        if (!response.ok) {
          throw new Error("Failed to send data to the database");
        }
      
        const data = await response.json(); // Parse the response data as JSON
        console.log(data);
      } catch (error) {
        console.error(error);
      }
      
    };
   sentEmail();
  };

  return (
    <div className="news-letter">
      <div className="container">
        <form autoComplete="none" noValidate>
          <p className="book-title">Subscribe to our newsletter</p>
          <form className="news-letter-form" onSubmit={handleSubmission}>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="submit" value="Subscribe" onClick={handleSubmission} />
          </form>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default NewsLetter;
