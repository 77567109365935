import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import SectionA from "../components/Home/SectionA";
import SectionB from "../components/Home/SectionB";
import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/NewsLetter/NewsLetter";
const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <SectionB />
      <SectionA />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
