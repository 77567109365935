import React,{useEffect} from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Search from "../components/Search/Search";
import BannerCards from "../components/PortofolioPageCards/BannerCards";
import PositionCards from "../components/PortofolioPageCards/PositionCards";

import PageTitle from "../components/PageTitle/PageTitle";

import LiveLiveLandingImage from "../images/photofolio_layout/livelivefilms.webp";

const Portfolio = () => {

  const PageTitle="LiveLive_Films"
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    <div>
      <Navbar />
      <PageTitle pageTitle="Portfolio" path="/about" />
      <Search />
      <BannerCards
        topicOne="Live Live"
        topicTwo="Films"
        portofolioPageImage={LiveLiveLandingImage}
      />
      <PositionCards  PageTitle={PageTitle} />
      <Footer />
    </div>
  );
};

export default Portfolio;
