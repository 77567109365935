import React,{useEffect} from "react";

import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Search from "../../components/Search/Search";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";
import Footer from "../../components/Footer/Footer";
import PortCards from "../../components/PortfolioCards/PortCards";

import AudioVisualImage from "../../images/photofolio_layout/AudioVisual.webp";

const Audio = () => {

  const PageTitle="audovisuals"
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    
    <div>
      <Navbar />
      <PageTitle pageTitle="Portfolio" path="/portfolio" />
      <Search />
      <BannerCards
        topicOne="Audio Visual"
        topicTwo="Production"
        portofolioPageImage={AudioVisualImage}
      />
      <PortCards  PageTitle={PageTitle} />
      <Footer />
    </div>
  );
};

export default Audio;
