import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from "../components/Footer/Footer"
import PhotofolioNavbar from '../components/PhotofolioNavbar/PhotofolioNavbar'
import PhotofolioGrid from '../components/PhotofolioGrid/PhotofolioGrid'


const Merchandise = () => {
  return (
    <div>
        <Navbar/>
        <PhotofolioNavbar/>
        <PhotofolioGrid/>
        <Footer/>
    </div>
  )
}

export default Merchandise