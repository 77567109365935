import { useEffect } from "react"; 
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Search from "../../components/Search/Search";
import PositionCards from "../../components/PortofolioPageCards/PositionCards";
import BannerCards from "../../components/PortofolioPageCards/BannerCards";
import Footer from "../../components/Footer/Footer";

import LiveLiveLandingImage  from "../../images/photofolio_layout/Animation Production.webp"

const Animation = () => {
  const PageTitle='Animations'
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div>
      <Navbar/>
      <PageTitle pageTitle="Portfolio" path="/pages/audio_visual" />
      <Search/>
      <BannerCards topicOne="Animation" topicTwo="" portofolioPageImage={LiveLiveLandingImage} />
      <PositionCards  PageTitle={PageTitle} />
      <Footer/>
    </div>
  );
};

export default Animation;
