import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Login from './components/Login/login_section';
import Signup from './components/Signup/signup_section';

import Home from './Pages/Home'; // this is the site's landing pageb
import About from './Pages/About';

import Portfolio from './Pages/Portfolio';  // this component is the livelive_films , the portfolio landing page
// primary portfolio componets 
import Audio from './Pages/AudioVisual/Audio';
import EventProduction from './Pages/Productions/EventProduction';
import Documentaries from './Pages/Documentaries/Documentaries';
// audio visual subcomponents
import Music_videos from './Pages/Music_videos/Music_videos';
import Animation from './Pages/PortAnimation/Animation';
import CommercialAdvertisement from './Pages/CommercialAdvertisement/CommercialAdvertisement';
import Wedding_Coverage from './Pages/Wedding_Coverage/Wedding_Coverage';

import Merchandise from './Pages/Merchandise'; // this component will serve as ecomerce plartform mountpoint for the sites 
import Dashboard from './Pages/Dashboard'; //this component will maintain the dashbaord context for either the adminstrative dashboard  for an admin or cusotmer dashboard for a client user 

import RegisterItem from "./components/PortfolioAdministration/RegisterItem"; // investigste the purpose of this component 
import ViewCSV from './components/ViewCSV/ViewCSV'; //investigate the purpose of this component

const App = () => {
  // State to manage authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle successful login
  const handleLogin = () => {
    // Update authentication status
    setIsAuthenticated(true);
  };

  // Function to handle logout
  // const handleLogout = () => {
  //   // Update authentication status
  //   setIsAuthenticated(false);
  // };

  // PrivateRoute component to handle protected routes
  const PrivateRoute = ({ element, ...rest }) => {
    return isAuthenticated ? element : <Navigate to='/administration/login' />;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>

            {/* Login route */}
          <Route path='/administration/login' element={<Login onLogin={handleLogin} />} />
          <Route path='/administration/signup' element={<Signup />} />

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />

          {/* portfolio routes */}
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/pages/audio_visual' element={<Audio />} />
          <Route path='/pages/events_production' element={<EventProduction />} />
          <Route path='/pages/Documentaries' element={<Documentaries />} />
          <Route path='/pages/commercial_advertisement' element={<CommercialAdvertisement />} />
          <Route path='/pages/animation' element={<Animation />} />
          <Route path='/pages/Music_videos' element={<Music_videos />} />
          <Route path='/pages/Wedding_Coverage' element={<Wedding_Coverage />} />
          
          <Route path='/administration/register' element={<RegisterItem />} />
          <Route path='/Merchandise' element={<Merchandise />} />
        
          {/* Protected routes */}
          {/* <Route path='/administration/dashboard' element={<PrivateRoute element={<Dashboard />} />} /> */}
          <Route path='/administration/dashboard' element={<Dashboard  />} />
          <Route path='/administration/displayed' element={<PrivateRoute element={<ViewCSV />} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};


export default App;
